@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,500,600,600i');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Mono&display=swap');

@import '../scss/color';
@import '../scss/typography';

@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$dynamic-app-light-theme: mat.define-light-theme(
    (
        color: (
            primary: mat.define-palette($dynamic-theme-primary),
            accent: mat.define-palette($dynamic-theme-accent),
        ),
    )
);
$dynamic-app-dark-theme: mat.define-dark-theme(
    (
        color: (
            primary: mat.define-palette($dynamic-theme-primary),
            accent: mat.define-palette($dynamic-theme-accent),
        ),
    )
);

.feq-theme {
    p,
    div,
    span,
    input {
        font-family: 'Roboto';
    }

    @include mat.all-component-typographies($feq-typography);
    @include mat.all-component-themes($dynamic-app-light-theme);

    .mat-mdc-button,
    .mat-mdc-raised-button,
    .mat-mdc-stroked-button,
    .mat-mdc-flat-button {
        border-radius: 0;
    }
}

.mta-theme {
    @include mat.all-component-typographies($mta-typography);
    @include mat.all-component-themes($dynamic-app-light-theme);

    p,
    div,
    span,
    input {
        font-family: 'Roboto';
    }

    .mat-mdc-button,
    .mat-mdc-raised-button,
    .mat-mdc-stroked-button,
    .mat-mdc-flat-button {
        border-radius: 0;
    }
    --mdc-snackbar-container-shape:0;
}

.tkp-theme {
    @include mat.all-component-typographies($tkp-typography);
    @include mat.all-component-themes($dynamic-app-light-theme);

    .mat-mdc-button,
    .mat-mdc-raised-button,
    .mat-mdc-stroked-button,
    .mat-mdc-flat-button {
        border-radius: 0;
    }

    p,
    div,
    span,
    input {
        font-family: 'Open Sans';
    }
    .tw-step-icon-selected {
        color: var(--theme-accent-500);
        font-family: "PT Mono" !important; font-weight: 400; font-style: normal;
    }
    .tw-step-icon-selected span{
        color: var(--theme-accent-500);
        font-family: "PT Mono" !important; font-weight: 400; font-style: normal;
    }
    .tw-btn-hover-transition .mdc-button__label:hover {
        color: white;
    }

    .tw-btn-hover-transition {
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    .tw-btn-hover-transition::after {
        content: '';
        background: #e6322d;
        position: absolute;
        z-index: -1;
        padding: 10px 20px;
        display: block;
        left: -20%;
        right: -20%;
        top: 0;
        bottom: 0;
        transform: skewX(-45deg) scale(0, 1);
    }

    .tw-btn-hover-transition:hover::after {
        transform: skewX(-45deg) scale(3, 1);
        transition: all 1s ease-out;
    }

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
        box-shadow:
            0px 1px 1px -2px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 1px 1px 0px rgba(0, 0, 0, 0.12) !important;
    }

    .mat-mdc-unelevated-button.mat-primary {
        --mdc-filled-button-label-text-color: white !important;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: #ffffff;
    }

    .dark-them {
        @include mat.all-component-themes($dynamic-app-dark-theme);
    }

    .mat-mdc-progress-bar {
        --mdc-linear-progress-active-indicator-color: white;
    }
    --mdc-elevated-card-container-shape:0;
    --mdc-fab-small-container-color: transparent;
    --mdc-snackbar-container-shape:0;
    //--mdc-fab-small-container-shape:4px;
     .mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary  {

        --mat-fab-foreground-color: #fff;
         --mat-fab-small-foreground-color: #fff;

    }
    .mat-mdc-fab.mat-primary:hover, .mat-mdc-mini-fab.mat-primary:hover  {
        background-color: var(--theme-primary-500);
        opacity: 0.9;

    }
    --mat-filled-button-icon-spacing: 0;
    //.mat-mdc-outlined-button:not(:disabled) {
    //    border-color: inherit !important;
    //    border-width: 3px;
    //    border-radius: 0;
    //}

    .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
        font-weight: 700 !important;
    }
}

.tw-primary {
    color: var(--primaryColor) !important;
    background-color: var(--primaryBackground) !important;
}

.tw-primary-color {
    color: var(--primaryBackground) !important;
}

.tw-accent {
    color: var(--accentColor) !important;
    background-color: var(--accentBackground) !important;
}

.tw-warn {
    color: var(--warnColor) !important;
    background-color: var(--warnBackground) !important;
}

.tw-app-background {

    max-width: 1200px;
    margin: auto;
    background-color: var(--appBackground) !important;
}
.tw-app-background-1 {

    max-width: 1200px;
    margin:10px auto;
    background-color: var(--appBackground) !important;
    min-height: 80vh;
}
.tw-app-background-tkp {
    margin:10px auto;
    background-color: var(--appBackground) !important;
    min-height: 80vh;
    max-width: 90%!important;
    box-shadow:none!important;
}

.tw-snack-bar {
    background: var(--theme-primary-600) !important;
    margin: 14px !important;
}

.tw-snack-bar-red {
    background: red !important;
    margin: 14px !important;
}

//.mat-expansion-panel-spacing{
//    margin:0 !important;
//
//}
.expansion-panel-header > span:nth-of-type(2) {
    display: none;
}

.header-title-temp {
    margin: 10px;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
    align-items: flex-start !important;
}

.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
    background: var(--theme-primary-500);
}

.mat-drawer-inner-container::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: var(--appBackground); /* or add it to the track */
}

/* Add a thumb */
.mat-drawer-inner-container::-webkit-scrollbar-thumb {
    background: var(--theme-primary-500);
    border-left: 1px var(--appBackground) solid;
}
.border-left {
    border-left-width: 8px;
    border-left-style: solid;

}
.border-left-color {
    border-left-color: var(--theme-primary-200);

    //border-image:url("https://ticketpro.s3.us-east-1.amazonaws.com/image/angular/trame-lignes_court.svg") 10;
}

.border-left-small {
    border-left-width: 3px;
    border-left-style: solid;
    border-left-color: var(--theme-primary-50);
}
.tw-app-background-tkp .border-left-color{
    border-image:url("/assets/resources/svg/border-0-5px.svg") 8;
    border-image-repeat: repeat;
}
.tw-tkp .border-left{
    border-image:url("/assets/resources/svg/border-0-5px.svg") 8;
    border-image-repeat: repeat;
}
.tw-tkp .border-left-small{
    border-image:url("/assets/resources/svg/border-0-5px.svg") 8;
    border-image-repeat: repeat;
}
.tw-app-background-tkp .plv-detail-disc{
    border-image:url("/assets/resources/svg/border-0-5px.svg") 6;
    border-image-repeat: repeat;
}
.tw-with-100{
    width: 100%;
}
.tw-stepper-vertical-line::before {
    content: "";
    position: absolute;
    left: 0;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: rgba(0, 0, 0, 0.12);
    top: calc(8px - calc((72px - 24px) / 2));
    bottom: calc(8px - calc((72px - 24px) / 2));
}
.tw-stepper-vertical-line{

    margin-left: 12px;
    border: 0;
    position: relative;
}
.tw-vertical-content-container{
    padding: 10px;
}

.tw-step-icon-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
}

 .tw-step-icon {
     border-radius: 50%;
     height: 24px;
     width: 24px;
     flex-shrink: 0;
     position: relative;
     border: 2px solid  var(--theme-accent-500);
     //color: var(--mat-stepper-header-icon-foreground-color);
     //background-color: var(--mat-stepper-header-icon-background-color);
 }
 .tw-step{
     align-items: center;
     margin: 24px 24px 0 0px;
 }


:root {
    --primaryColor: #fff;
    --primaryBackground: #151a4f;
    --accentColor: rgba(0, 0, 0, 0.87);
    --accentBackground: #008000;
    --warnColor: #fff;
    --warnBackground: #f44336;
    --appBackground: #fff;
    --mdc-filled-button-label-text-color: #fff !important;
}

@media screen and (max-width: 728px) {
    .tw-app-background {
        margin: 0px 0px 20px 0px !important;
    }
}
@media screen and (max-width: 500px) {
 .tw-stepper-vertical-line{
     margin-left: 10px;
 }
    .tw-step{
        margin: 24px 0px;
    }
    .tw-stepper-vertical-line::before {

        top: -40px;
        bottom: -35px;
    }
    .tw-app-background-tkp {
        margin:0px auto;
        background-color: var(--appBackground) !important;
        min-height: 80vh;
        max-width:100% !important;

    }
}
.tw-input-pl-body{
    background: var(--theme-primary-200);
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
}
.tw-app-background-tkp .tw-input-pl-body{
   background: #f4f4f9;
    opacity: 1;
}
.tw-app-background-tkp .cl-day-number-active{
    border-bottom-color: #46A2D6 !important;
}
.tw-app-background-tkp .list-detail-date {
   color: black !important;
}
::-webkit-scrollbar-thumb {
    background: var(--theme-primary-500);
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: var(--appBackground); /* or add it to the track */
}

.mat-mdc-form-field-error {
    display: flex!important;
}

.mat-mdc-form-field-error-wrapper {
    position: inherit!important;
}
