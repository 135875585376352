@use '@angular/material' as mat;

@import '../projects/shared-lib/src/lib/scss/config';

.mat-mdc-form-field-error{
    display: flex !important;
}
.mat-mdc-form-field-error-wrapper {
   position: inherit !important;
}
//.mat-mdc-form-field {
//    display: flow !important;
//}
.tw-form-error-car{
    background: #80808047;
    color: black;
    margin: 0 2px;
    padding: 0 5px;
    font-weight: bold;
}
.material-symbols-outlined {
    font-family: 'Material Symbols Outlined' !important;
    font-variation-settings:
        'FILL' 0,
        'wght' 300,
        'GRAD' 0,
        'opsz' 24;
}
.material-icons{
    font-family: 'Material Symbols Outlined' !important;
}
.pl-divider {
    border-top-style: dashed !important;margin: 22px 0 !important;
}
.bk-carre {
    background-color: #dadada;
    background-image: url(data:image/gif;base64,R0lGODlhBQAFAOMAAP/14////93uHt3uHt3uHt3uHv///////////wAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAAAALAAAAAAFAAUAAAQL0ACAzpG0YnonNxEAOw==);

}
.service-fees-color {
    color: #00000091
}
.price-color {
    font-weight: 700;
}
