@use '@angular/material' as mat;

$feq-typography: mat.define-typography-config(
    $font-family: 'Roboto',
    $headline-1: mat.define-typography-level(96px, 112px, 300,null,-1.5),
    $headline-2: mat.define-typography-level(60px, 56px, 400),
    $headline-3: mat.define-typography-level(48px, 48px, 400),
    $headline-4: mat.define-typography-level(34px, 40px, 400),
    $headline-5: mat.define-typography-level(24px, 32px, 400),
    $headline-6: mat.define-typography-level(20px, 32px, 500),
    $subtitle-1: mat.define-typography-level(16px, 22px, 500),
    $subtitle-2: mat.define-typography-level(14px, 24px, 400),
    $body-1: mat.define-typography-level(16px, 24px, 500),
    $body-2: mat.define-typography-level(14px, 30px, 400),
    $button: mat.define-typography-level(14px, 14px, 500),
    $caption: mat.define-typography-level(12px, 20px, 400),
    $overline: mat.define-typography-level(10px, 20px, 400),
);
$mta-typography: mat.define-typography-config(
    $font-family: 'Roboto, Arial, Helvetica, sans-serif',
    $headline-1: mat.define-typography-level(96px, 112px, 300,null,-1.5),
    $headline-2: mat.define-typography-level(60px, 56px, 400),
    $headline-3: mat.define-typography-level(48px, 48px, 400),
    $headline-4: mat.define-typography-level(34px, 40px, 400),
    $headline-5: mat.define-typography-level(24px, 32px, 400),
    $headline-6: mat.define-typography-level(20px, 32px, 500),
    $subtitle-1: mat.define-typography-level(16px, 22px, 500),
    $subtitle-2: mat.define-typography-level(14px, 24px, 400),
    $body-1: mat.define-typography-level(16px, 24px, 500),
    $body-2: mat.define-typography-level(14px, 30px, 400),
    $button: mat.define-typography-level(14px, 14px, 500),
    $caption: mat.define-typography-level(12px, 20px, 400),
    $overline: mat.define-typography-level(10px, 20px, 400),
);
@font-face {
    font-family: 'Open-Sans';
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


}
$tkp-typography: mat.define-typography-config(
    $font-family: 'Open Sans',
    $headline-1: mat.define-typography-level(60px, 60px, 700,null,-1.5),
    $headline-2: mat.define-typography-level(48px, 30px, 700),
    $headline-3: mat.define-typography-level(35px,  30px, 700),
    $headline-4: mat.define-typography-level(24px,  30px , 600),
    $headline-5: mat.define-typography-level(20px, 30px, 600),
    $headline-6: mat.define-typography-level(16px, 30px, 600),
    $subtitle-1: mat.define-typography-level(16px, 30px, 500),
    $subtitle-2: mat.define-typography-level(14px, 30px, 400),
    $body-1: mat.define-typography-level(16px, 24px, 500),
    $body-2: mat.define-typography-level(14px, 30px, 400),
    $button: mat.define-typography-level(16px, 14px, 500),
    $caption: mat.define-typography-level(12px, 20px, 400),
    $overline: mat.define-typography-level(10px, 20px, 400),
    // Line-height must be unit-less fraction of the font-size.
    // $input:         mat-typography-level(inherit, 1.125, 400)
);
