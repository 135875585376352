@use '@angular/material' as mat;
$light-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);
$dynamic-theme-primary: (
    50: var(--theme-primary-50),
    100: var(--theme-primary-100),
    200: var(--theme-primary-200),
    300: var(--theme-primary-300),
    400: var(--theme-primary-400),
    500: var(--theme-primary-500),
    600: var(--theme-primary-600),
    700: var(--theme-primary-700),
    800: var(--theme-primary-800),
    900: var(--theme-primary-900),
    A100: var(--theme-primary-A100),
    A200: var(--theme-primary-A200),
    A400: var(--theme-primary-A400),
    A700: var(--theme-primary-A700),
    contrast: (
        50: var(--theme-primary-contrast-50),
        100: var(--theme-primary-contrast-100),
        200: var(--theme-primary-contrast-200),
        300: var(--theme-primary-contrast-300),
        400: var(--theme-primary-contrast-400),
        500: var(--theme-primary-contrast-500),
        600: var(--theme-primary-contrast-600),
        700: var(--theme-primary-contrast-700),
        800: var(--theme-primary-contrast-800),
        900: var(--theme-primary-contrast-900),
        A100: var(--theme-primary-contrast-A100),
        A200: var(--theme-primary-contrast-A200),
        A400: var(--theme-primary-contrast-A400),
        A700: var(--theme-primary-contrast-A700),
    ),
);

$dynamic-theme-accent: (
    50: var(--theme-accent-50),
    100: var(--theme-accent-100),
    200: var(--theme-accent-200),
    300: var(--theme-accent-300),
    400: var(--theme-accent-400),
    500: var(--theme-accent-500),
    600: var(--theme-accent-600),
    700: var(--theme-accent-700),
    800: var(--theme-accent-800),
    900: var(--theme-accent-900),
    A100: var(--theme-accent-A100),
    A200: var(--theme-accent-A200),
    A400: var(--theme-accent-A400),
    A700: var(--theme-accent-A700),
    contrast: (
        50: var(--theme-accent-contrast-50),
        100: var(--theme-accent-contrast-100),
        200: var(--theme-accent-contrast-200),
        300: var(--theme-accent-contrast-300),
        400: var(--theme-accent-contrast-400),
        500: var(--theme-accent-contrast-500),
        600: var(--theme-accent-contrast-600),
        700: var(--theme-accent-contrast-700),
        800: var(--theme-accent-contrast-800),
        900: var(--theme-accent-contrast-900),
        A100: var(--theme-accent-contrast-A100),
        A200: var(--theme-accent-contrast-A200),
        A400: var(--theme-accent-contrast-A400),
        A700: var(--theme-accent-contrast-A700),
    )
);
$tkp-blue-palette: (
    50: #818ede,
    100: #4558ce,
    200: #2e40ad,
    300: #1f2b74,
    400: #1f2b74,
    500: #121944,
    600: #0c102c,
    700: #0c102c,
    800:#000000,
    900:#000000,
    A100: #ea80fc,
    A200: #e040fb,
    A400: #d500f9,
    A700: #aa00ff,
    contrast: (
        50:$light-primary-text ,
        100: $light-primary-text,
        200: $light-primary-text,
        300:$light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100:$light-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

.mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-container-color: var(--theme-primary-500);
    --mdc-protected-button-label-text-color: var(--theme-primary-contrast-500) !important;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {

    --mdc-fab-icon-color: var(--theme-primary-contrast-500) !important;;
    --mat-mdc-fab-color: var(--theme-primary-contrast-500) !important;;
}


